export const dLocalCountries = ['AR', 'BR', 'CL', 'CO', 'CR', 'EC', 'MX', 'PA', 'PE'];

export const achCountryList = ['US'];

export const bankWireRestrictedCodes = [
  'GF',
  'GY',
  'PY',
  'SR',
  'VE',
  'CU',
  'DO',
  'HT',
  'HT',
  'BZ',
  'SV',
  'HN',
  'NI',
];

export const pushToCardAvailableCountries = [
  'AL',
  'AD',
  'AT',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MC',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
];

export const prepaidCardAvailableCountries = [
  'AD',
  'AM',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FO',
  'FI',
  'FR',
  'GE',
  'DE',
  'GI',
  'GR',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'UA',
  'GB',
  'VA',
  'CA',
  'AE',
];

export const europeCountries = [
  'AD',
  'AL',
  'AT',
  'AX',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GG',
  'GR',
  'HR',
  'HU',
  'IE',
  'IM',
  'IC',
  'IT',
  'JE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SE',
  'SI',
  'SJ',
  'SK',
  'SM',
  'UA',
  'VA',
];
